import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import mq from '../styles/breakpoints'
import { baseTheme } from '../styles/theme'
import { COLORS } from '@borrowell/bw-styles'
import { ICreditCardSection } from '../graphql/sections/creditCard'

import { Heading, Paragraph, Superscript } from '../components/typography'
import { Button } from '../components/Button'
import { Image } from '../components/Image'
import { RichText } from '../components/RichText'

interface IProductDetailsSection {
  data: ICreditCardSection
}

interface IProduct {
  propertiesData: { title: string; text: string }[]
}

interface IExpandable {
  isExpanded: boolean
}

const Section = styled.div`
  ${mq({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    border: `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
    margin: ['22px 20px', '15px 31px', '30px 42px'],
    boxShadow: `5px 12px 13px -13px ${COLORS.NEUTRAL.COOL['300']}`,
  })}
`
const SectionOne = styled.div`
  ${mq({
    width: '100%',
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    justifyContent: ['center', 'center', 'space-between'],
    alignItems: 'center',
    padding: ['30px 27px', '30px', '30px 40px'],
  })}
`

const ProductInfo = styled.div`
  ${mq({
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    justifyContent: ['center', 'center', 'space-between'],
    alignItems: 'center',
  })}
`

const PropertyCSS = css`
  div:last-child {
    border-right: none;
  }
  ${mq({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: ['wrap', 'wrap', 'nowrap'],
    alignItems: 'center',
    padding: ['0 27px', '0 46px', '0'],
  })}
`

const SectionTwo = styled.div`
  ${PropertyCSS}
`

const Property = styled.div`
  ${mq({
    width: ['50%', '50%', '100%'],
    height: ['70px', '70px', '110px'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: ['left', 'left', 'center'],
    borderTop: ['none', 'none', `0.25px solid ${COLORS.NEUTRAL.COOL['300']}`],
    borderBottom: ['none', 'none', `0.25px solid ${COLORS.NEUTRAL.COOL['300']}`],
    borderRight: ['none', 'none', `0.25px solid ${COLORS.NEUTRAL.COOL['300']}`],
  })}
`

const SectionThree = styled.div`
  ${mq({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  })}
`

const DescriptionSection = styled.div<IExpandable>(({ isExpanded }) =>
  mq({
    width: '100%',
    maxHeight: isExpanded ? '1000px' : '200px',
    padding: ['17px 27px 23px 27px', '10px 46px 30px 46px', '20px 23px'],
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      opacity: isExpanded ? '0' : '1',
      transition: 'opacity 400ms cubic-bezier(0.5, 0, 0.5, 1)',
      position: 'absolute',
      bottom: '0',
      height: '100%',
      width: '100%',
      content: "''",
      background: 'linear-gradient(to top, rgba(255,255,255, 1) 10%,  rgba(255,255,255, 0) 50%)',
      pointerEvents: 'none',
    },
    animationName: isExpanded ? 'growMaxHeight' : 'shrinkMaxHeight',
    animationTimingFunction: 'cubic-bezier(0.5, 0, 0.5, 1)',
    animationDuration: '200ms',
    '@keyframes growMaxHeight': {
      '0%': {
        maxHeight: '200px',
      },
      '100%': {
        maxHeight: '1000px',
      },
    },
    '@keyframes shrinkMaxHeight': {
      '0%': {
        maxHeight: '1000px',
      },
      '100%': {
        maxHeight: '200px',
      },
    },
  }),
)

const SectionFour = styled.div`
  ${mq({
    width: '100%',
    height: ['61px', '61px', '72px'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: `1px solid ${COLORS.NEUTRAL.COOL['300']}`,
  })}
`

const ExpandButton = styled.button`
  ${mq({
    fontFamily: 'Lato',
    fontSize: ['16px', '18px', '18px'],
    lineHeight: ['1.28', '1.28', '1.89'],
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    fontWeight: 'bold',
    padding: '10px',
    color: COLORS.PURPLE['700'],
    backgroundColor: 'white',
    border: 'none',
    cursor: 'pointer',
    ':hover,:active': {
      color: COLORS.PRIMARY.DEFAULT,
      outline: 'none',
      '& > div, & > div:before': {
        backgroundColor: COLORS.PRIMARY.DEFAULT,
      },
    },
  })}
`
const ExpandIcon = styled.div<IExpandable>(({ isExpanded }) =>
  mq({
    position: 'relative',
    alignSelf: 'center',
    transition: 'transform 0.5s ease',
    width: '20px',
    height: '3px',
    backgroundColor: COLORS.PURPLE['700'],
    transform: isExpanded ? 'rotate(180deg)' : '',
    borderRadius: '1px',
    marginLeft: '10px',
    '&:before': {
      display: 'block',
      content: '""',
      width: '20px',
      height: '3px',
      transform: isExpanded ? 'rotate(180deg)' : 'rotate(90deg)',
      transition: 'transform 0.5s ease',
      backgroundColor: COLORS.PURPLE['700'],
      borderRadius: '1px',
    },
  }),
)

const imageStyles = {
  minWidth: ['266px', '266px', '210px'],
  height: ['165px', '167px', '142px'],
}

const productTitleStyles = {
  fontFamily: 'Lato',
  textAlign: ['center', 'center', 'left'],
  flexWrap: 'wrap',
  padding: ['19px 28px', '30px 0', '0 40px'],
}

const buttonStyles = {
  width: ['266px', '266px'],
  maxWidth: ['100%', '100%'],
  margin: 0,
  fontSize: ['14px', '18px', '18px'],
  lineHeight: ['1.86', '1.44', '1.44'],
  fontWeight: 'bold',
}

const propertiesTitleStyles = {
  fontFamily: 'Lato',
  fontSize: ['12px', '12px', '17px'],
  textAlign: 'left',
  margin: '0',
  padding: ['0', '0', '15px 20px 0 20px'],
  alignSelf: 'flex-start',
  color: COLORS.NEUTRAL.COOL['900'],
}

const propertiesTextStyles = {
  fontFamily: 'Lato',
  fontSize: ['19px', '19px', '21px'],
  textAlign: 'left',
  fontWeight: 'bold',
  lineHeight: '1.42',
  margin: '0',
  padding: ['1px 0', '1.7px 0', '15px 31px'],
  alignSelf: ['left', 'left', 'center'],
  color: COLORS.NEUTRAL.COOL['900'],
}

const someStyle = {
  paragraph: {
    textAlign: 'left',
    margin: '15px 22px',
    maxWidth: '855px',
  },
  list: {
    ...baseTheme.list,
    '& > li:last-of-type': {
      marginBottom: '20px',
    },
  },
  listElement: {
    maxWidth: '877px',
    paddingLeft: '22px',
    lineHeight: 1.44,
    margin: '15px 0px',
    '& > p': {
      margin: '15px 0px',
    },
  },
}

const customRichTextHeadingsStyles = {
  textAlign: 'left',
  width: '100%',
}

const customH3Styles = {
  marginBottom: ['', '15px', ''],
}

const customH4Styles = {
  marginBottom: ['5px', '10px', ''],
}

const customRichTextStyles = {
  paragraph: {
    textAlign: 'left',
    width: '100%',
    margin: ['', '', '12px 0 25px 0'],
    maxWidth: undefined,
  },
  'heading-1': customRichTextHeadingsStyles,
  'heading-2': customRichTextHeadingsStyles,
  'heading-3': { ...customRichTextHeadingsStyles, ...customH3Styles },
  'heading-4': { ...customRichTextHeadingsStyles, ...customH4Styles },
  'heading-5': customRichTextHeadingsStyles,
  'unordered-list': {
    padding: ['0 0 30px 0px'],
  },
}

export const Properties: React.FC<IProduct> = ({ propertiesData }) => {
  return (
    <>
      {propertiesData.map(({ title, text }) => (
        <Property key={title}>
          <Paragraph styleOverrides={propertiesTitleStyles}>{title}</Paragraph>
          <Paragraph styleOverrides={propertiesTextStyles}>{text || 'N/A'}</Paragraph>
        </Property>
      ))}
    </>
  )
}

const CreditCardDetails: React.FC<IProductDetailsSection> = ({
  data: {
    name,
    ctaButton,
    displayCtaButton,
    field1Content,
    field1Title,
    field2Content,
    field2Title,
    field3Content,
    field3Title,
    field4Content,
    field4Title,
    description,
    image: imageData,
    contentful_id,
    __typename,
  },
}) => {
  const productTitle = name.replace(/<[^>]+>/g, '')
  const imgAltText = `${productTitle} credit card`
  const properties = [
    { title: field1Title, text: field1Content },
    { title: field2Title, text: field2Content },
    { title: field3Title, text: field3Content },
    { title: field4Title, text: field4Content },
  ]
  const fluidImg = imageData.image.gatsbyImageData

  const link = ctaButton?.link ?? ''
  const linkRel = ctaButton?.rel
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Section>
      <SectionOne>
        <ProductInfo>
          {!!imageData && <Image fluid={fluidImg} alt={imgAltText} customStyles={imageStyles} />}
          <Heading as="h4" styleOverrides={productTitleStyles}>
            {productTitle && <Superscript>{productTitle}</Superscript>}
          </Heading>
        </ProductInfo>
        {displayCtaButton && (
          <Button
            to={link}
            name="View Product"
            styleOverrides={buttonStyles}
            style={buttonStyles}
            rel="sponsored"
            contentfulId={contentful_id}
            typeName={__typename}
          >
            {ctaButton?.linkText ?? 'View Product'}
          </Button>
        )}
      </SectionOne>
      <SectionTwo>
        <Properties propertiesData={properties} />
      </SectionTwo>
      <SectionThree>
        <DescriptionSection isExpanded={isExpanded}>
          <RichText content={description} customStyles={customRichTextStyles} />
        </DescriptionSection>
      </SectionThree>
      <SectionFour>
        <ExpandButton name={isExpanded ? 'Hide Details' : 'Show Details'} onClick={() => setIsExpanded(cur => !cur)}>
          {isExpanded ? 'Hide Details' : 'Show Details'} <ExpandIcon isExpanded={isExpanded} />
        </ExpandButton>
      </SectionFour>
    </Section>
  )
}

export default CreditCardDetails
